<!-- DashboardComponent.vue -->
<template>
    <div class="w-11/12 2xl:w-4/5 2xl:min-w-[1050px] overflow-x-auto mx-auto grid grid-cols-1 divide-y divide-slate-300"
        :class="isLoading ? 'cursor-wait' : ''">
        <div class="text-xl text-bold ma-5 mt-10 flex justify-between">
            <span class="text-2xl">Dashboard</span>
        </div>
        <div>
            <div class="ml-5 mt-5 text-xl">{{ $t('conferenceName') }} {{ conferenceName }}</div>
            <div class="ml-5 mt-2 flex">
                <div class="text-xl">{{ $t('conferenceUrl') }}</div>
                <a class="text-lg ml-2 text-blue-600 hover:text-blue-800 hover:cursor-pointer" :href="listenUrl"
                    target="_blank">{{ listenUrl }}</a>
            </div>
            <div class="min-w-[1020px] mt-8">
                <div class="flex justify-between w-[1020px] mt-5">
                    <div class="ml-5 text-xl">{{ $t('currentLanguageSelection') }}</div>
                </div>
                <div class="ml-10 mt-5 w-[1010px] h-[440px] overflow-y-auto ">
                    <OriginalLanguageButton :propsEditedName="originalName" :propsIsChecked="isOriginalActive"
                        :propsCurrentListeners="listeners[0]?.currentListeners ? listeners[0]?.currentListeners : 0"
                        :propsIsPlaying="isOriginalPlaying" :propsSourceLanguage="selectedSourceLanguage"
                        :isToggleLoading="isToggleLoading" @checkboxChanged="isActive => isOriginalActive = isActive"
                        @playstop="toggleOriginalPlaying()" @nameChanged="name => originalName = name"
                        @sourcelanguageSelected="selectSourceLanguage" />

                    <AdminLanguageButton v-for="(button, index) in languageButtons" :key="button.index" class="mt-2"
                        :propsIsOriginalLanguageSet="selectedSourceLanguage !== null"
                        :propsEditedName="button.channelName" :propsIsChecked="button.isActive"
                        :propsCurrentOption="button.currentOption"
                        :propsCurrentListeners="listeners[button.id]?.currentListeners ? listeners[button.id]?.currentListeners : 0"
                        :propsTargetLanguage="button.selectedTargetLanguage ? button.selectedTargetLanguage : null"
                        :propsIsTranslationRunning="liveTranslationActive"
                        @checkboxChanged="updateCheckbox(index, $event)" @nameChanged="updateName(index, $event)"
                        @optionSelected="updateOption(index, $event)" @shareClicked="openLanguageModal(index)"
                        @closeClicked="removeLanguageButton(index)"
                        @targetlanguageSelected="selectTargetLanguage(button.id, $event)" />
                </div>
            </div>
            <div class="w-[1000px] h-[40px] ml-10 mt-6 flex">
                <div class="w-[500px] h-[40px] border rounded-xl flex items-center mr-1">
                    <input type="checkbox" :checked="startTime.isActive" v-model="startTime.isActive"
                        class="w-4 h-4 ml-6 border-slate-400 rounded" :disabled="!isChrome" />
                    <div class="text ml-6">
                        {{ $t('automaticStart') }}
                    </div>
                    <div class="ml-4">
                        <input id="startHour" type="text" v-model="startTime.hour" @input="handleStartHourInput"
                            class="border border-gray-400 rounded-md px-1 mr-2 w-7 focus:outline-none"
                            placeholder="10" />:
                        <input id="startHour" type="text" v-model="startTime.minute" @input="handleStartMinuteInput"
                            class="border border-gray-400 rounded-md px-1 mr-2 w-7 focus:outline-none"
                            placeholder="00" />
                    </div>
                </div>
                <div class="w-[500px] h-[40px] border rounded-xl flex items-center ml-1">
                    <input type="checkbox" :checked="stopTime.isActive" v-model="stopTime.isActive"
                        class="w-4 h-4 ml-6 border-slate-400 rounded" />
                    <div class="text ml-6">
                        {{ $t('automaticStop') }}
                    </div>
                    <div class="ml-4">
                        <input id="startHour" type="text" v-model="stopTime.hour" @input="handleStopHourInput"
                            class="border border-gray-400 rounded-md px-1 mr-2 w-7 focus:outline-none" min="0" max="23"
                            placeholder="11" />:
                        <input id="startHour" type="text" v-model="stopTime.minute" @input="handleStopMinuteInput"
                            class="border border-gray-400 rounded-md px-1 mr-2 w-7 focus:outline-none" min="0" max="59"
                            placeholder="30" />
                    </div>
                </div>
            </div>
            <div class="w-[1000px] h-[75px] mb-10 ml-10 mt-4 ">

                <!-- Button für Start der Live-Übersetzung -->
                <div v-if="!liveTranslationActive" class="flex" :class="isLoading ? 'cursor-wait' : ''">
                    <!-- <div class="text-3xl text-white text-center pt-5">
                        Speichern und Übersetzung Starten
                    </div> -->
                    <div class="w-[500px] h-[75px]  border-1 bg-blue-400 hover:bg-blue-500 mr-1 rounded-xl cursor-pointer"
                        @click="publishChanges()">
                        <div class="text-3xl text-white text-center pt-5">{{ $t('saveChanges') }}
                        </div>
                    </div>
                    <div class="w-[500px] h-[75px] border-1 ml-1 rounded-xl" @click="startLiveTranslation"
                        :class="!isChrome || !enoughCredits ? 'bg-slate-400' : 'bg-blue-400 hover:bg-blue-500 cursor-pointer'">
                        <div class="text-3xl text-white text-center pt-5" v-if="!startTime.isActive">
                            <div v-if="!isLoading">{{ $t('startTranslation') }}</div>
                            <div class="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white-500 mx-auto"
                                v-else>
                            </div>
                            <v-tooltip v-if="!isChrome" activator="parent" location="top" color="#E5E5E5">
                                {{ $t('errorOnlyUseChrome') }}
                            </v-tooltip>
                            <v-tooltip v-if="!enoughCredits" activator="parent" location="top" color="#E5E5E5">
                                {{ $t('errorNotEnoughCredits') }}
                            </v-tooltip>
                        </div>
                        <div v-else>
                            <div v-if="!isLoading">
                                <div class="text-3xl text-white text-center mt-2">
                                    <div>{{ $t('startTranslation') }}</div>
                                </div>
                                <div class="text-white rounded-xl bg-red px-3 text-center mx-auto w-fit">
                                    {{ $t('automaticStartAt') }} {{ startTime.hour }}:{{
                                        startTime.minute }}
                                </div>
                            </div>
                            <div class="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white-500 mx-auto mt-5"
                                v-else>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Button für Stopp der Live-Übersetzung -->
                <div v-else class="flex">
                    <div v-if="!isTranslationPaused" @click="togglePauseLiveTranslation"
                        class="w-[500px] h-[75px]  border-1 bg-red-500 hover:bg-red-600 mr-1 rounded-xl">
                        <div class="text-3xl text-white text-center pt-5">{{ $t('pauseAITranslation') }}
                        </div>
                    </div>
                    <div v-else @click="togglePauseLiveTranslation"
                        class="w-[500px] h-[75px]  border-1 bg-blue-400 hover:bg-blue-500 mr-1 rounded-xl">
                        <div class="text-3xl text-white text-center pt-5">{{ $t('continueAITranslation') }}
                        </div>
                    </div>
                    <div @click="stopLiveTranslation"
                        class="w-[500px] h-[75px]  border-1 bg-red-500 hover:bg-red-600 ml-1 rounded-xl">
                        <div class="text-3xl text-white text-center pt-5" v-if="!stopTime.isActive">{{
                            $t('stopTranslation') }}
                        </div>
                        <div v-else>
                            <div class="text-3xl text-white text-center mt-2">{{ $t('stopTranslation') }}
                            </div>
                            <div class="text-white rounded-xl bg-blue-400 px-3 text-center mx-auto w-fit">{{
                                $t('automaticStopAt') }} {{ stopTime.hour }}:{{ stopTime.minute }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <WarningModal :show="showWarningModal" :remainingMinutes="warningTimeInMinutes" @continue="continueTranslation"
            @stop="stopTranslation" />
    </div>
</template>

<script>
import AdminLanguageButton from './AdminLanguageButton.vue';
import OriginalLanguageButton from './OriginalLanguageButton.vue';
import WarningModal from './WarningModal.vue';
import LogoMitSchrift from '@/assets/Logo mit schrift.png';
import AgoraRTC from "agora-rtc-sdk-ng";
import { markRaw } from "vue";
import { ref, update, set, off, onValue, remove, push } from 'firebase/database';
import { rtdb, functions } from '../firebaseInit';
import { httpsCallable } from 'firebase/functions';
import * as speechsdk from 'microsoft-cognitiveservices-speech-sdk';


export default {
    components: { AdminLanguageButton, OriginalLanguageButton, WarningModal },
    data() {
        return {
            logoMitSchrift: LogoMitSchrift,
            languageButtons: [],
            showModal: false,
            selectedSourceLanguage: null,
            liveTranslationActive: false,
            recordingStatus: "ended",
            remoteRecordingStatus: {
                aiTranslationActive: true,
                liveTranslationActive: false,
                initiatedBy: 'desktop'
            },
            conferenceId: null,
            isTranslationPaused: false,
            FrontendUrl: window.location.origin,
            conferenceName: 'Konferenz Name',
            conferenceUrl: '',
            freeCredits: 0,
            streamCode: null,
            client: markRaw(AgoraRTC.createClient({ mode: "rtc", codec: "h264" })),
            testSpeakerClient: markRaw(AgoraRTC.createClient({ mode: "rtc", codec: "h264" })),
            testListeningClient: markRaw(AgoraRTC.createClient({ mode: "rtc", codec: "h264" })),
            localAudioTrack: null,
            isOriginalPlaying: false,
            originalName: "Originalton",
            isOriginalActive: true,
            isLoading: false,
            isToggleLoading: false,
            loggingKey: "",
            listeners: [],
            isChrome: false,
            startTime: {
                isActive: false,
                hour: '10',
                minute: '00',
            },
            stopTime: {
                isActive: false,
                hour: '11',
                minute: '30',
            },
            autoStartTimeout: null,
            autoStopTimeout: null,
            inactivityTimeInMinutes: 10,
            warningTimeInMinutes: 5,
            showWarningModal: false,
            inactivityTimer: null,
            warningTimer: null,
            lastActivityTimestamp: Date.now(),
            // Azure Transcription
            azureSpeechToken: null,
            segmentationTimeout: 200,
            hardSegmentationTimeout: 12000,
            hardSegmentationTimer: null,
            lastProcessedTimestamp: null,
            currentRecognizer: null,
            nextRecognizer: null,
            heartbeatInterval: null,
            tokenRenewalInterval: null,
            // Firebase Functions
            generateRtcToken: httpsCallable(functions, 'generateRtcToken'),
            changeListenerCount: httpsCallable(functions, 'changeListenerCount'),
            translateAndSpeekAudio: httpsCallable(functions, 'translateAndSpeekAudio'),
            getAzureSpeechToken: httpsCallable(functions, 'getAzureSpeechToken'),
        };
    },
    mounted() {
        window.addEventListener('beforeunload', this.handleBeforeUnload);
    },
    async created() {
        this.addEmptyLanguageButton();
        this.getConferenceInformation().then(() => {
            this.watchListenerChanges();
            this.watchRemoteRecordingStatus();
        });
        this.checkBrowser();
        this.generateRtcToken({ reduceColdStart: true });
        this.changeListenerCount({ reduceColdStart: true });
        this.getAzureSpeechToken({ reduceColdStart: true });
    },
    watch: {
        languageButtons: {
            handler(newValue) {
                if (newValue.length === 0) {
                    this.addEmptyLanguageButton();
                } else {
                    const lastButton = newValue[newValue.length - 1];
                    if (lastButton.channelName !== '') {
                        this.addEmptyLanguageButton();
                    }
                }
                if (this.recordingStatus !== "ended") {
                    this.updateRecordingStatus();
                }
            },
            deep: true,
        },
        startTime: {
            handler(newValue) {
                if (newValue.isActive) {
                    const now = new Date();
                    let start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), parseInt(newValue.hour), parseInt(newValue.minute));
                    if (start < now) start.setDate(start.getDate() + 1);
                    const diff = start - now;
                    if (this.autoStartTimeout !== null) {
                        clearTimeout(this.autoStartTimeout);
                    }

                    this.autoStartTimeout = setTimeout(() => {
                        if (this.liveTranslationActive) return;
                        this.startLiveTranslation();
                        this.startTime.isActive = false;
                    }, diff);
                }
            },
            deep: true,
        },
        stopTime: {
            handler(newValue) {
                if (newValue.isActive) {
                    const now = new Date();
                    let stop = new Date(now.getFullYear(), now.getMonth(), now.getDate(), parseInt(newValue.hour), parseInt(newValue.minute));
                    if (stop < now) stop.setDate(stop.getDate() + 1);
                    const diff = stop - now;

                    if (this.autoStopTimeout !== null) {
                        clearTimeout(this.autoStopTimeout);
                    }

                    this.autoStopTimeout = setTimeout(() => {
                        if (!this.liveTranslationActive) return;
                        this.stopLiveTranslation();
                        this.stopTime.isActive = false;
                    }, diff);
                }
            },
            deep: true,
        },
        liveTranslationActive() {
            this.updateInactivityState();
        },
        isTranslationPaused() {
            this.updateInactivityState();
        },
        conferenceChannels: {
            deep: true,
            handler() {
                this.updateInactivityState();
            }
        },
        isAITranslationActive(newVal, oldVal) {
            if (newVal && !oldVal) {
                // Recognizer starten, wenn isAITranslationActive von false zu true wechselt
                this.startAzureTranscription();
            } else if (!newVal && oldVal) {
                // Recognizer stoppen, wenn isAITranslationActive von true zu false wechselt
                this.stopAzureTranscription();
            }
        },
    },
    computed: {
        targetLanguageCodes() {
            return this.conferenceChannels.filter(channel => channel.useAI && channel.targetLanguage).map(channel => channel.targetLanguage.code);
        },
        listenUrl() {
            return `${this.FrontendUrl}/listen/${this.conferenceUrl}`;
        },
        conferenceChannels() {
            const originalChannel = {
                id: 0,
                channelName: this.originalName,
                isActive: this.isOriginalActive,
                isMuted: false,
                useAI: false,
                speakerStreamCode: this.conferenceId,
                targetLanguage: "",
                translationUrls: [],
            }
            const otherChannels = this.languageButtons.filter(button => button.channelName).map(button => {
                const useAI = button.currentOption === 'Nutze KI';
                return {
                    id: button.id,
                    channelName: button.channelName,
                    isActive: button.isActive,
                    isMuted: true,
                    useAI: useAI,
                    speakerStreamCode: useAI ? '' : button.speakerStreamCode,
                    targetLanguage: useAI && button.selectedTargetLanguage ? button.selectedTargetLanguage : "",
                    translationUrls: [],
                };
            });
            return [originalChannel, ...otherChannels];
        },
        enoughCredits() {
            return this.freeCredits >= this.targetLanguageCodes.length * 5 || this.$store.state.planActive;
        },
        isAITranslationActive() {
            return this.liveTranslationActive &&
                !this.isTranslationPaused &&
                this.conferenceChannels.some(channel => channel.isActive && channel.useAI);
        },
    },
    methods: {
        getLanguageCode(language) {
            return language.code;
        },
        async startAzureTranscription() {
            const createRecognizer = async () => {
                const { data } = await this.getAzureSpeechToken();
                const { token, region } = data;
                const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(token, region);
                const languageCode = this.getLanguageCode(this.selectedSourceLanguage);
                speechConfig.speechRecognitionLanguage = languageCode;
                speechConfig.setProperty(speechsdk.PropertyId.Speech_SegmentationSilenceTimeoutMs, this.segmentationTimeout.toString());
                speechConfig.setProperty(speechsdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs, "0");

                const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
                const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);

                recognizer.recognized = (s, e) => {
                    if (e.result.reason === speechsdk.ResultReason.RecognizedSpeech) {
                        clearTimeout(this.hardSegmentationTimer);
                        this.hardSegmentationTimer = setTimeout(() => {
                            recognizer.stopContinuousRecognitionAsync();
                            recognizer.startContinuousRecognitionAsync();
                        }, this.hardSegmentationTimeout);

                        const transcriptionText = e.result.text;
                        this.processTranscription(transcriptionText);
                    }
                };

                recognizer.canceled = async (s, e) => {
                    console.error(`Transkription unerwartet abgebrochen. Grund: ${e.reason}, Details: ${e.errorDetails}`);
                    await this.stopLiveTranslation();
                };

                return recognizer;
            };

            const switchToNextRecognizer = async () => {
                if (this.nextRecognizer) {
                    if (this.currentRecognizer) {
                        await this.currentRecognizer.stopContinuousRecognitionAsync();
                        this.currentRecognizer.close();
                    }
                    this.currentRecognizer = this.nextRecognizer;
                    this.nextRecognizer = null;
                    console.log("Gewechselt zum nächsten Recognizer");
                } else {
                    console.error("Kein nächster Recognizer verfügbar");
                }
            };

            // Initial setup
            this.currentRecognizer = await createRecognizer();
            this.currentRecognizer.startContinuousRecognitionAsync();

            // Periodische Erneuerung, behebt plötzliche Abbrüche, sowie Token-Erneuerung
            this.renewalInterval = setInterval(async () => {
                console.log("Bereite nächsten Recognizer vor");
                this.nextRecognizer = await createRecognizer();
                this.nextRecognizer.startContinuousRecognitionAsync();
                await switchToNextRecognizer();
            }, 9 * 60 * 1000); // Alle 9 Minuten

            const startTime = Date.now();
            this.heartbeatInterval = setInterval(() => {
                const duration = Math.round((Date.now() - startTime) / 1000 / 60);
                console.log(`Dauer der Transkription: ${duration} Minuten`);
            }, 30 * 1000); // Alle 30 Sekunden prüfen
        },
        async stopAzureTranscription() {
            const unaccountedTime = Math.round((Date.now() - this.lastProcessedTimestamp) / 1000);
            if (unaccountedTime > 0) {
                this.translateAndSpeekAudio({
                    transcription: "",
                    duration: unaccountedTime,
                    targetLanguages: this.targetLanguageCodes.join(','),
                    sourceLanguage: this.selectedSourceLanguage.code,
                    conferenceId: this.conferenceId,
                    loggingKey: this.loggingKey
                });
            }

            clearInterval(this.renewalInterval);
            clearInterval(this.heartbeatInterval);
            clearInterval(this.tokenRenewalInterval);
            clearTimeout(this.hardSegmentationTimer);

            if (this.currentRecognizer) {
                await this.currentRecognizer.stopContinuousRecognitionAsync();
                this.currentRecognizer.close();
                this.currentRecognizer = null;
            }
            if (this.nextRecognizer) {
                this.nextRecognizer.close();
                this.nextRecognizer = null;
            }
        },
        processTranscription(transcriptionText) {
            const currentTimestamp = Date.now();
            const duration = Math.max(Math.round((currentTimestamp - this.lastProcessedTimestamp) / 1000), 1);
            this.lastProcessedTimestamp = currentTimestamp;

            console.log("Transcription:", transcriptionText, "Duration:", duration);
            const targetLanguageCodesString = this.conferenceChannels
                .filter(channel => channel.isActive && channel.useAI)
                .map(channel => channel.targetLanguage.code)
                .join(',');

            if (transcriptionText && this.isAITranslationActive) {
                this.translateAndSpeekAudio({
                    transcription: transcriptionText,
                    duration: duration,
                    targetLanguages: targetLanguageCodesString,
                    sourceLanguage: this.selectedSourceLanguage.code,
                    conferenceId: this.conferenceId,
                    loggingKey: this.loggingKey
                });
                this.updateLastActivityTimestamp();
            }
        },
        updateInactivityState() {
            if (this.isAITranslationActive) {
                this.startInactivityTimer();
            } else {
                this.stopInactivityTimer();
                this.stopWarningTimer();
            }
        },
        startInactivityTimer() {
            this.stopInactivityTimer();
            if (this.isAITranslationActive) {
                this.inactivityTimer = setTimeout(() => {
                    this.showWarningModal = true;
                    this.startWarningTimer();
                }, (this.inactivityTimeInMinutes - this.warningTimeInMinutes) * 60 * 1000);
            }
        },
        stopInactivityTimer() {
            if (this.inactivityTimer) {
                clearTimeout(this.inactivityTimer);
                this.inactivityTimer = null;
            }
        },
        startWarningTimer() {
            this.warningTimer = setTimeout(() => {
                this.stopTranslation();
            }, this.warningTimeInMinutes * 60 * 1000);
        },
        stopWarningTimer() {
            if (this.warningTimer) {
                clearTimeout(this.warningTimer);
                this.warningTimer = null;
            }
        },
        updateLastActivityTimestamp() {
            if (this.isAITranslationActive) {
                this.lastActivityTimestamp = Date.now();
                this.startInactivityTimer();
                this.showWarningModal = false;
                this.stopWarningTimer();
            }
        },
        continueTranslation() {
            this.showWarningModal = false;
            this.stopWarningTimer();
            this.updateLastActivityTimestamp();
        },
        async stopTranslation() {
            this.showWarningModal = false;
            this.stopWarningTimer();
            this.stopInactivityTimer();
            await this.stopLiveTranslation();
        },
        checkBrowser() {
            this.isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
            if (!this.isChrome) {
                this.$store.dispatch('showSnackbar', { message: this.$t('pleaseUseChrome'), timeout: -1 });
            }
        },
        handleStartHourInput(event) {
            const inputValue = event.target.value;
            if (isNaN(Number(inputValue)) || inputValue.length === 0 || Number(inputValue) < 0 || Number(inputValue) > 23) {
                this.startTime.hour = '00';
                return;
            }
            if (inputValue.length === 3 && inputValue[0] === '0') {
                this.startTime.hour = inputValue.slice(1);
                return;
            }
            if (inputValue.length > 2) return;
            this.startTime.hour = inputValue;
        },
        handleStartMinuteInput(event) {
            const inputValue = event.target.value;
            if (isNaN(Number(inputValue)) || inputValue.length === 0 || Number(inputValue) < 0 || Number(inputValue) > 59) {
                this.startTime.minute = '00';
                return;
            }
            if (inputValue.length === 3 && inputValue[0] === '0') {
                this.startTime.minute = inputValue.slice(1);
                return;
            }
            if (inputValue.length > 2) return;
            this.startTime.minute = inputValue;
        },
        handleStopHourInput(event) {
            const inputValue = event.target.value;
            if (isNaN(Number(inputValue)) || inputValue.length === 0 || Number(inputValue) < 0 || Number(inputValue) > 23) {
                this.stopTime.hour = '00';
                return;
            }
            if (inputValue.length === 3 && inputValue[0] === '0') {
                this.stopTime.hour = inputValue.slice(1);
                return;
            }
            if (inputValue.length > 2) return;
            this.stopTime.hour = inputValue;
        },
        handleStopMinuteInput(event) {
            const inputValue = event.target.value;
            if (isNaN(Number(inputValue)) || inputValue.length === 0 || Number(inputValue) < 0 || Number(inputValue) > 59) {
                this.stopTime.minute = '00';
                return;
            }
            if (inputValue.length === 3 && inputValue[0] === '0') {
                this.stopTime.minute = inputValue.slice(1);
                return;
            }
            if (inputValue.length > 2) return;
            this.stopTime.minute = inputValue;
        },
        selectSourceLanguage(value) {
            this.selectedSourceLanguage = value;
        },
        selectTargetLanguage(buttonId, value) {
            const button = this.languageButtons.find(button => button.id === buttonId)
            button.selectedTargetLanguage = value;
            if (button.channelName === '' && value?.real_name) {
                // Regex to extract the part before the parentheses and trim any trailing spaces
                const languageNameRegex = /^(.*?)\s*\(/;
                const match = value.real_name.match(languageNameRegex);

                if (match && match[1]) {
                    button.channelName = match[1].trim();
                } else {
                    // Fallback to the original behavior if the regex doesn't match
                    button.channelName = value.real_name;
                }
            }
        },
        publishChanges() {
            this.isLoading = true;
            setTimeout(() => {
                this.updateRecordingStatus();
                this.isLoading = false;
            }, 100);
        },
        async toggleOriginalPlaying() {
            document.body.style.cursor = 'wait';
            this.isToggleLoading = true;
            if (!this.isOriginalPlaying) {
                // Originalton darf maximal 5 minuten laufen
                setTimeout(() => { if (this.isOriginalPlaying) this.toggleOriginalPlaying() }, 300000); // 5 Minuten
                const isSpeakerPublisher = true;
                const channelName = "test" + this.conferenceId.toString();

                const speakerResponse = await this.generateRtcToken({ channelName: channelName, isPublisher: isSpeakerPublisher }).catch((error) => { console.error('Error:', error.message); });
                await this.testSpeakerClient.join(speakerResponse.data.appId, channelName, speakerResponse.data.token, speakerResponse.data.uid);

                const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
                await this.testSpeakerClient.publish([localAudioTrack]);

                const isListenerPublisher = false;
                const listenerResponse = await this.generateRtcToken({ channelName: channelName, isPublisher: isListenerPublisher }).catch((error) => { console.error('Error:', error.message); });
                await this.testListeningClient.join(listenerResponse.data.appId, channelName, listenerResponse.data.token, listenerResponse.data.uid);

                this.testListeningClient.on("user-published", async (user, mediaType) => {
                    await this.testListeningClient.subscribe(user, mediaType);
                    if (mediaType === "audio") {
                        const audioTrack = user.audioTrack;
                        audioTrack.play();
                    }
                });
            } else {
                await this.testListeningClient.leave();
                await this.testSpeakerClient.leave();
            }
            this.isOriginalPlaying = !this.isOriginalPlaying;
            document.body.style.cursor = 'default';
            this.isToggleLoading = false;
        },
        updateRecordingStatus() {
            update(ref(rtdb, `conferences/${this.conferenceId}`), {
                recordingStatus: this.recordingStatus,
                sourceLanguage: this.selectedSourceLanguage,
                channels: this.conferenceChannels,
            }).catch((error) => console.error('Fehler beim Aktualisieren von conferences:', error));

            this.languageButtons.forEach(button => {
                if (button.currentOption === 'Nutze Sprecher') {
                    update(ref(rtdb, `speaker/${button.speakerStreamCode}`), { ownName: button.channelName });
                }
            });
        },
        watchListenerChanges() {
            const listenerRef = ref(rtdb, `conferences/${this.conferenceId}/listeners/channels`);
            onValue(listenerRef, (snapshot) => {
                if (snapshot.exists()) {
                    this.listeners = snapshot.val();
                }
            });
        },
        async waitForRef(ref, timeout = 5000) {
            let timeoutId;
            const cleanup = () => {
                clearTimeout(timeoutId);
                off(ref);
            };
            return new Promise((resolve, reject) => {
                timeoutId = setTimeout(() => { cleanup(); reject(new Error('Timeout waiting for user data')); }, timeout); // Timeout after 5 seconds
                onValue(ref, (snapshot) => {
                    if (snapshot.exists()) {
                        cleanup(); resolve(snapshot.val());
                    }
                }, (error) => { cleanup(); reject(error); });
            });
        },
        async getConferenceInformation() {
            const statusRef = ref(rtdb, `users/${this.$store.state.user.uid}`);
            const statusSnapshot = await this.waitForRef(statusRef).catch(() => window.location.reload());
            const conferenceRef = ref(rtdb, `conferences/${statusSnapshot.conferenceId}`);
            const conferenceSnapshot = await this.waitForRef(conferenceRef).catch(() => window.location.reload());

            this.conferenceName = conferenceSnapshot.conferenceName;
            this.conferenceUrl = conferenceSnapshot.conferenceUrl;
            this.conferenceId = conferenceSnapshot.conferenceId;
            this.streamCode = conferenceSnapshot.conferenceId;
            this.selectedSourceLanguage = conferenceSnapshot.sourceLanguage;
            this.freeCredits = statusSnapshot.freeCredits;
            this.inactivityTimeInMinutes = statusSnapshot.inactivityTimeInMinutes || 10;
            this.warningTimeInMinutes = statusSnapshot.warningTimeInMinutes || 5;
            this.segmentationTimeout = statusSnapshot.segmentationTimeout || 200;
            if (conferenceSnapshot.channels && conferenceSnapshot.channels.length > 0) {
                this.channels = conferenceSnapshot.channels.slice(1);
                this.originalName = conferenceSnapshot.channels[0].channelName || "Originalton";
            } else {
                this.channels = [];
                this.originalName = "Originalton";
            }
            this.loadSettings();
        },
        loadSettings() {
            if (this.channels) {
                this.languageButtons = this.channels.map(channel => {
                    return {
                        id: channel.id,
                        channelName: channel.channelName,
                        isActive: channel.isActive,
                        currentOption: channel.useAI ? 'Nutze KI' : 'Nutze Sprecher',
                        speakerStreamCode: channel.speakerStreamCode,
                        selectedTargetLanguage: channel.targetLanguage,
                    };
                });
            }
        },
        async watchRemoteRecordingStatus() {
            const remoteRecordingStatusRef = ref(rtdb, `conferences/${this.conferenceId}/remoteRecordingStatus`);
            onValue(remoteRecordingStatusRef, async (snapshot) => {
                if (snapshot.exists()) {
                    if (snapshot.val().initiatedBy === 'desktop') return;
                    const isLiveTranslationStatusDifferent = (this.remoteRecordingStatus.liveTranslationActive !== snapshot.val().liveTranslationActive)
                    const isAITranslationStatusDifferent = (this.remoteRecordingStatus.aiTranslationActive !== snapshot.val().aiTranslationActive)
                    if (isLiveTranslationStatusDifferent) {
                        this.remoteRecordingStatus.liveTranslationActive = snapshot.val().liveTranslationActive;
                        if (this.remoteRecordingStatus.liveTranslationActive && !this.liveTranslationActive) {
                            if (this.isLoading) return;
                            console.log('Start Live Translation from remote');
                            await this.startLiveTranslation();
                        } else {
                            if (!this.remoteRecordingStatus.liveTranslationActive && this.liveTranslationActive) {
                                await this.stopLiveTranslation();
                            }
                        }
                    }
                    if (isAITranslationStatusDifferent) {
                        this.togglePauseLiveTranslation();
                        this.remoteRecordingStatus.aiTranslationActive = snapshot.val().aiTranslationActive;
                    }
                }
            });
        },
        async updateRemoteRecordingStatus() {
            const conferenceRemoteRef = ref(rtdb, `conferences/${this.conferenceId}/remoteRecordingStatus`);
            const remoteRecordingStatus = this.remoteRecordingStatus;
            remoteRecordingStatus.initiatedBy = 'desktop';
            return await update(conferenceRemoteRef, remoteRecordingStatus);
        },
        updateCheckbox(index, value) {
            this.languageButtons[index].isActive = value;
        },
        updateName(index, value) {
            this.languageButtons[index].channelName = value;
        },
        async updateOption(index, option) {
            const value = option === 'useAI' ? 'Nutze KI' : 'Nutze Sprecher';
            this.languageButtons[index].currentOption = value;

            if (value !== 'Nutze KI' && !this.languageButtons[index].speakerStreamCode) {
                // Create speakerStreamCode
                const newSpeakerRef = push(ref(rtdb, 'speaker'));
                const speakerData = {
                    conferenceId: this.conferenceId,
                    conferenceName: this.conferenceName,
                    originalStreamCode: this.conferenceId,
                    originalName: "Originalton",
                    ownStreamCode: newSpeakerRef.key,
                    ownName: '',
                };
                await set(newSpeakerRef, speakerData);
                this.languageButtons[index].speakerStreamCode = newSpeakerRef.key;
            }
        },
        async openLanguageModal(index) {
            if (this.languageButtons[index].currentOption === 'Nutze Sprecher') {
                const qrCodeLink = `${this.FrontendUrl}/speaker/${this.languageButtons[index].speakerStreamCode}`;
                this.$store.dispatch('showQRModal', { link: qrCodeLink, text: this.$t('interpreterUrl') });
            }
        },
        removeLanguageButton(index) {
            if (this.languageButtons.length === 1) {
                this.languageButtons = [];
                this.addEmptyLanguageButton();
                return;
            }
            const button = this.languageButtons[index];
            if (button.currentOption === 'Nutze Sprecher') {
                remove(ref(rtdb, `speaker/${button.speakerStreamCode}`));
            }

            this.languageButtons.splice(index, 1);
            this.updateRecordingStatus();
        },
        addEmptyLanguageButton() {
            // id soll eine 3-stellige Zahl (ungleich 0) sein, um die Eindeutigkeit zu gewährleisten überprüfe ob die id bereits existiert
            do {
                var id = Math.floor(Math.random() * 1000 + 1).toString();
            } while (this.languageButtons.some(button => button.id === id));

            this.languageButtons.push({
                id: id,
                isActive: false,
                currentOption: 'Nutze KI',
                channelName: '',
                speakerStreamCode: '',
                selectedTargetLanguage: null,
            });
        },
        async startLiveTranslation() {
            if (this.isLoading || this.liveTranslationActive || !this.isChrome || !this.enoughCredits) return;
            console.log('Start Live Translation');
            this.isLoading = true;
            try {
                // wenn keine Sprachpaare ausgewählt sind, wird die Live-Übersetzung nicht gestartet
                if (this.languageButtons.filter(button => button.isActive).length === 0 && !this.isOriginalActive) {
                    this.$store.dispatch('showSnackbar', { message: this.$t('pleaseSelectLanguage') });
                    this.isLoading = false;
                    return;
                }
                const loggingResponse = await this.translateAndSpeekAudio({ isStarting: true }).catch((error) => { console.error('Error:', error.message); });

                this.loggingKey = loggingResponse.data.loggingKey;

                const presenceRef = ref(rtdb, `conferences/${this.conferenceId}/presence/status`);
                await set(presenceRef, {
                    isActive: true,
                    initiatedBy: 'desktop',
                    loggingKey: this.loggingKey,
                    uid: this.$store.state.user.uid
                });

                const channelName = this.conferenceId.toString();
                const response = await this.generateRtcToken({ channelName: channelName, isPublisher: true }).catch((error) => { console.error('Error:', error.message); });

                await this.client.join(response.data.appId, channelName, response.data.token, response.data.uid);

                this.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
                await this.client.publish([this.localAudioTrack]);

                this.lastProcessedTimestamp = Date.now();

                this.recordingStatus = "running";
                this.updateRecordingStatus();

                this.liveTranslationActive = true;
                this.remoteRecordingStatus.liveTranslationActive = true;
                await this.updateRemoteRecordingStatus();
                this.$store.commit('setLiveTranslationActive', true);

                this.updateLastActivityTimestamp();
            } finally {
                this.isLoading = false;
            }
        },
        async stopLiveTranslation() {
            console.log('Stop Live Translation');
            if (!this.liveTranslationActive) return;
            this.liveTranslationActive = false;
            this.remoteRecordingStatus.liveTranslationActive = false;
            await this.updateRemoteRecordingStatus();
            this.$store.commit('setLiveTranslationActive', false);
            this.recordingStatus = "ended";
            this.updateRecordingStatus();

            this.localAudioTrack.close();
            await this.client.leave();
            await remove(ref(rtdb, `conferences/${this.conferenceId}/presence/status`));
        },
        togglePauseLiveTranslation() {
            if (this.isTranslationPaused) {
                this.isTranslationPaused = false;
                this.recordingStatus = "running";
                this.updateRecordingStatus();
                this.remoteRecordingStatus.aiTranslationActive = true;
                this.updateRemoteRecordingStatus();
            } else {
                this.isTranslationPaused = true;
                this.recordingStatus = "paused";
                this.updateRecordingStatus();
                this.remoteRecordingStatus.aiTranslationActive = false;
                this.updateRemoteRecordingStatus();
            }
        },
        handleBeforeUnload() {
            remove(ref(rtdb, `conferences/${this.conferenceId}/presence/status`));
        },
    },
    beforeUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    },
};
</script>

<style>
.v-tooltip>.v-overlay__content {
    background-color: rgb(241 245 249) !important;
    color: red !important;
}
</style>